<template>

    <div id="subscription-info-page">
        <v-row>
            <v-col cols="12" style="padding: 0">
                <subscription-expiration />
            </v-col>
        </v-row>


        <v-row>
            <v-col cols="9">
                <vx-card :title="$t('component_subscriptionInfo_title')">

                    <div class="subscription-plan-info-container">
                        <subscription-plan-info :with-change-subscription-button="true"></subscription-plan-info>
                    </div>

                    <h4>{{ $t('component_subscriptionInfo_limitTitle') }}</h4>

                    <div class="usage-detail-container">
                        <div class="vx-row">
                            <div class="vx-col w-full md:w-5/12 lg:w-5/12 xl:w-5/12">
                                <p>{{ $t('component_subscriptionInfo_limitProjectCount') }}<strong>{{ projectUsage.used|formatNumber }}</strong>/<span v-if="projectUsage.total">{{ projectUsage.total|formatNumber }}</span></p>
                            </div>
                            <div class="vx-col w-full md:w-7/12 lg:w-7/12 xl:w-7/12">
                                <vs-progress :percent="projectUsage.percent" :height="10" :color="getBarColor(projectUsage.percent)"></vs-progress>
                            </div>
                        </div>
                    </div>


                    <div class="usage-detail-container">
                        <div class="vx-row">
                            <div class="vx-col w-full md:w-5/12 lg:w-5/12 xl:w-5/12">
                                <p>{{ $t('component_subscriptionInfo_limitKeywordsCount') }}<strong>{{ keywordUsage.used|formatNumber }}</strong>/<span v-if="keywordUsage.total">{{ keywordUsage.total|formatNumber }}</span></p>
                            </div>
                            <div class="vx-col w-full md:w-7/12 lg:w-7/12 xl:w-7/12">
                                <vs-progress :percent="keywordUsage.percent" :height="10" :color="getBarColor(keywordUsage.percent)"></vs-progress>
                            </div>
                        </div>

                        <vs-collapse class="show-detail" style="display: block; margin: auto">
                            <vs-collapse-item>
                                <div slot="header">
                                    {{ $t('component_subscriptionInfo_keywordList') }}
                                </div>
                                <div>
                                    <ag-grid-table
                                        :withTableMenu="false"
                                        :show-floating-filter="false"
                                        :table-data="{columnDefs:columnDefs,rowData:projectsSummary.data}"
                                    />
                                </div>
                            </vs-collapse-item>
                        </vs-collapse>
                    </div>
                </vx-card>
            </v-col>
        </v-row>

    </div>

</template>


<script>

import AgGridTable from '@/components/yooda-components/ag-grid-table/AgGridTable'
import SubscriptionExpiration from "@/components/yooda-components/SubscriptionExpiration";
import SubscriptionPlanInfo from "@/components/yooda-components/SubscriptionPlanInfo";
import {mapGetters} from "vuex";
import apiCalls from "@/api/axios/configuration/project";

export default {
    data() {
        return {
            showDetail:false,
            columnDefs:[
                {headerName: this.$t('component_subscriptionInfo_table_header_project'), field: 'name'},
                {headerName: this.$t('component_subscriptionInfo_table_header_keyword'), field: 'distinctKeywordCount', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber'},
                {headerName: this.$t('component_subscriptionInfo_table_header_location'), field: 'distinctSearchGeolocationCount', width: 100, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber'},
                {headerName: this.$t('component_subscriptionInfo_table_header_request'), field: 'requestCount', width: 90, cellClass:'ag-grid-cell-right', headerClass:'ag-grid-header-right', cellRendererFramework: 'CellRendererFormatedNumber'},
            ],
        }
    },
    computed:{
        ...mapGetters({
            activeSubscription:"customersManager/getActiveSubscription",
            maxProjectCount:"customersManager/getMaxProjectCount",
            maxRequestCount:"customersManager/getMaxRequestCount",
            additionalRequestCount:"customersManager/getAdditionalRequestCount",
            additionalProjectCount:"customersManager/getAdditionalProjectCount",
        }),
        projectUsage(){

            let percent = 0
            let used = (this.projectsSummary.data ? this.projectsSummary.data.length : 0)
            if(this.maxProjectCount() > 0){percent = (used * 100) / (this.maxProjectCount()+this.additionalProjectCount())}
            return {used:used,total:this.maxProjectCount()+this.additionalProjectCount(),percent:percent}
        },
        keywordUsage(){
            let percent = 0
            let used = (this.projectsSummary.data ? this.projectsSummary.data.reduce((acc, project)=>acc + (project.distinctKeywordCount*project.distinctSearchGeolocationCount),0) : 0)
            if(this.maxRequestCount() > 0){percent = (used * 100) / (this.maxRequestCount()+this.additionalRequestCount())}
            return {used:used,total:this.maxRequestCount()+this.additionalRequestCount(),percent:percent}
        },
        billingPortalLink(){
            return this.$store.state.customersManager.customer.billingPortal.url
        }
    },
    methods:{
        getBarColor(percent){
            if(percent > 25){ return this.getThemeColorByName('progressBar2')}
            if(percent > 50){ return this.getThemeColorByName('progressBar3')}
            if(percent > 75){ return this.getThemeColorByName('progressBar4')}
            return this.getThemeColorByName('progressBar1')
        },

        formatData(jsonData){
            if(jsonData.content.length === 0){return []}
            let rowData = []
            jsonData.content.forEach(data => {
                rowData.push({
                    name:data.name,
                    distinctKeywordCount: data.distinctKeywordCount,
                    distinctSearchGeolocationCount: data.distinctSearchGeolocationCount,
                    requestCount: parseInt(data.requestCount),
                })
            })
            return rowData
        },

        initComponentData(){
            this.setDataComponent({
                stateName:'projectsSummary',
                promise: apiCalls.getProjectsSummary(this.$route.params.subscriptionId),
                dataFormatter: this.formatData
            })
        },
    },
    components: {
        AgGridTable,
        SubscriptionExpiration,
        SubscriptionPlanInfo,
    },
    beforeMount() {
        this.initComponentData()
    },
    mounted() {
        this.analyticsIdentify()
    }
}

</script>

<style lang="scss">

    .subscription-plan-info-container{
        background-color: #ebf3fa;
        padding: 6px 12px;
        border-radius: 4px;
        margin: 0 0 32px 0;
    }

    .usage-detail-container{
        margin: 24px 6px;
        padding: 24px 6px;
        border: 1px solid $grey;
        border-radius: 4px;
        p{
            strong{
                margin-left: 24px;
            }
        }

        .show-detail{
            .vs-collapse-item--header{
                width:130px;
                margin:auto;
                color: $primary;
            }

            .ag-body-horizontal-scroll{
                display: none !important;
            }
        }
    }

</style>
