<template>
    <div id="subscription-plan-info-component">

        <div class="vx-row" v-if="activeSubscription().product">

            <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                <h5>{{ $t(`common_subscription_plan_name_${activeSubscription().product.handle}`) }}</h5>
                <span>{{ $t('component_subscriptionPlanInfo_remainingData') }} {{ referenceDate }}</span>

                <vs-button
                    id="change-subscription-link"
                    v-if="withChangeSubscriptionButton"
                    style="display: block; width: 160px"
                    color="primary"
                    size="small"
                    @click="openPurchase"
                >
                    {{ $t('component_subscriptionPlanInfo_changeSubscription') }}
                </vs-button>
                <vs-button
                    id="billing-portal-link"
                    v-if="withChangeSubscriptionButton"
                    style="display: block; width: 160px"
                    color="primary"
                    type="border"
                    size="small"
                    @click="openBillingPortal"
                >
                    {{ $t('component_subscriptionPlanInfo_manageSubscription') }}
                </vs-button>
            </div>

            <div class="vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
                <p v-if="maxProjectCount()">{{ maxProjectCount()|formatNumber }} {{ $t('component_subscriptionPlanInfo_projectCount') }}</p>
                <p v-if="maxRequestCount()">{{ maxRequestCount()|formatNumber }} {{ $t('component_subscriptionPlanInfo_keywordCount') }}</p>
                <p v-if="maxRivalCountPerProject()">{{ maxRivalCountPerProject()|formatNumber }} {{ $t('component_subscriptionPlanInfo_competitorCount') }}</p>
                <p v-if="maxGeolocationCountPerProject()">{{ maxGeolocationCountPerProject()|formatNumber }} {{ $t('component_subscriptionPlanInfo_geolocationCount') }}</p>
                <p v-if="additionalRequestCount()">option: +{{ additionalRequestCount()|formatNumber }} {{ $t('component_subscriptionPlanInfo_keywordCount') }}</p>
                <p v-if="additionalProjectCount()">option: +{{ additionalProjectCount()|formatNumber }} {{ $t('component_subscriptionPlanInfo_projectCount') }}</p>
            </div>
        </div>
    </div>
</template>




<script>

import {mapGetters} from "vuex";

    export default {
        props:{
            withChangeSubscriptionButton:false
        },
        computed: {
            ...mapGetters({
                activeSubscription:"customersManager/getActiveSubscription",
                maxProjectCount:"customersManager/getMaxProjectCount",
                maxRequestCount:"customersManager/getMaxRequestCount",
                maxRivalCountPerProject:"customersManager/getMaxRivalCountPerProject",
                maxGeolocationCountPerProject:"customersManager/getMaxGeolocationCountPerProject",
                additionalProjectCount:"customersManager/getAdditionalProjectCount",
                additionalRequestCount:"customersManager/getAdditionalRequestCount",
            }),
            referenceDate() {
                return this.$options.filters.displayDateLocale(this.activeSubscription().referenceDate)
            },
            purchasePlanUrl(){
                return new URL(`${process.env.VUE_APP_CUSTOMERS_MANAGER_FRONTEND_BASE_URI}/purchase/select-plan?subscription-id=${this.activeSubscription().id}`).href
            },
        },
        methods: {
            openPurchase(){
                // window.open(this.purchasePlanUrl, '_blank')
                window.open(this.purchasePlanUrl, '_blank')
            },
            openBillingPortal(){
                // window.open(this.purchasePlanUrl, '_blank')
                window.open(this.$store.state.customersManager.customer.billingPortal.url, '_blank')
            }
        },

    }
</script>

<style lang="scss" >

#subscription-plan-info-component{

    padding: 6px 0 12px 12px;

    .vx-col{
        min-width: 265px;
        max-width: 270px;

        >h5{
            font-size: 15px;
            font-weight: 600;
            margin: 6px 0 0 0;
        }
        >span{
            display: block;
            margin: 6px 0 12px 0;
            font-size: 10px;
            opacity: 0.8;
            font-style: italic;
            font-weight: 300;
        }
        >p{
            font-size: 11px;
            opacity: 0.7;
            font-style: italic;
        }
        .vs-button{
            margin: 12px 0 0 0;
        }
    }



}


</style>
